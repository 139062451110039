import { FunctionComponent } from "react";
import { AiFillGithub } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";

interface FooterProps {}

const Footer: FunctionComponent<FooterProps> = () => {
  return (
    <div className="bg-dark-lighten text-white flex justify-between items-center py-3 px-4 shadow-md mt-3">
      <p className="flex gap-2">
        <span>MoviesList does not host any files, it only links to 3rd party services. Legal issues should be taken up with the file hosts and providers. MoviesList is not responsible for any media files shown by the video providers.</span>
      </p>
    
    </div>
  );
};

export default Footer;
